import React, {useEffect, useState} from "react";
import {Redirect} from 'react-router-dom';
import GoogleLogin from "react-google-login";
import "../../styles/Login.css";
import GoogleLogo from "../../images/google-logo.png";
import axios from "axios";

const url = process.env.REACT_APP_BRASA_API_URL

function Login() {
    /*
    * 0: Ainda Não foi processado
    * 1: Login Aprovado
    * 2: Login não pôde ser realizado
    */
    const [googleStatusError, setGoogleStatusError] = useState(0);

    useEffect(function (){
        localStorage.removeItem(process.env.REACT_APP_JWT_TOKEN);
    }, []);

    function responseGoogle(resp) {
        axios.post(`${url}/api/auth/login`, {
            idToken: resp.tokenId
        })
            .then(res => {
                if (res.status === 200) {
                    localStorage.setItem(process.env.REACT_APP_JWT_TOKEN, res.data.token);
                    setGoogleStatusError(1);
                } else {
                    setGoogleStatusError(2);
                }
            })
            .catch(err => {
                console.log(err)
                setGoogleStatusError(2);
            });
    }

    return (
        <main>
            <div className='background-brasa'>
                <div className="box">
                    <div className="text-instruction">
                        <h1>Login</h1>
                        <p>Por favor, entre com sua conta @gobrasa.org.</p>
                    </div>
                    <GoogleLogin
                        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                        render={renderProps => (
                            <button onClick={renderProps.onClick} className="google-login"><img src={GoogleLogo} alt="Google"/>
                                <span>Login</span></button>
                        )}
                        buttonText="Login"
                        onSuccess={responseGoogle}
                        onFailure={() => setGoogleStatusError(2)}
                        cookiePolicy={'single_host_origin'}
                    />
                    <p style={googleStatusError === 2 ? {display: "block"} : {}} className="error">
                        Erro no login. Tente usar sua conta da BRASA, se o erro persistir contate tech@gobrasa.org.
                    </p>
                </div>
            </div>
            {googleStatusError === 1 ? <Redirect to='/dashboard'/> : ""}
        </main>
    );
}

export default Login;
import Home from "./components/pages/Home";
import Login from "./components/pages/Login";
import Dashboard from "./components/pages/Dashboard";
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import HttpsRedirect from 'react-https-redirect';
import {ProtectedRoute} from "./ProtectedRoute";
import Relatorio from "./components/pages/Relatorio";

function App() {
  return (
      <HttpsRedirect>
          <Router>
              <div>
                  <Switch>
                      <Route path="/pos-graduacao" exact component={Home}/>
                      <Route path="/admin" exact component={Login}/>
                      <Route path="/relatorio/:id" exact component={Relatorio}/>
                      <ProtectedRoute path="/dashboard" exact component={Dashboard}/>
                  </Switch>
              </div>
          </Router>
      </HttpsRedirect>
  );
}

export default App;

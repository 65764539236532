import React, {useState} from "react";
import logo from '../images/brasa.png';
import userDefault from '../images/user.png';
import '../styles/Nav.css';

function Nav() {
    const [status, setStatus] = useState(false);

    function logout() {
        localStorage.removeItem(process.env.REACT_APP_MY_USER);
        window.location = "/admin";
    }
    const user = JSON.parse(localStorage.getItem(process.env.REACT_APP_MY_USER));
    return (
        <nav>
            <header className="bg-grey">
                <div className="container">
                    <a href={"/admin"}><img src={logo} alt="Fundamentos Grad | BRASA"/></a>
                    <div id="nav-icon" className={status ? "open" : ""} onClick={() => setStatus(!status)}>
                        <span/>
                        <span/>
                        <span/>
                        <span/>
                    </div>
                </div>
            </header>
            <div id="mainMenu" className={`bg-light-grey align-items-center ${status ? "d-flex" : "d-none"}`}>
                <div className="camada-menu"/>
                <ul className="mx-auto my-auto text-center">
                    <li><img src={user.imageUrl !== null ? user.imageUrl : userDefault} alt="User"/></li>
                    <hr/>
                    <li><a href="/">Ver Site</a></li>
                    <li><a onClick={logout} href="javascript:void(0);">Logout</a></li>
                    <hr/>
                </ul>
            </div>
        </nav>
    );
}

export default Nav;
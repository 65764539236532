import React, { Component } from 'react';
import Form from "./Form";
import Relatorio from "./Relatorio";
import axios from "axios";

const url = process.env.REACT_APP_BRASA_API_URL

class MyComponent extends Component {

    /**
     * This is where the magic happens. State is and object that only holds the page
     * where the user currently is. Form is the object that contains the user input.
     * Main only render these two components while keeps the info from them.
     */

    state = {
        step: 0,
        title: 'Pós-Graduação',
        email: '',
        name: '',
        lastName: '',
        answers: {
            inglesReading: 0,
            inglesListening: 0,
            inglesWriting: 0,
            inglesSpeaking: 0,
            provasToeflIeltsDuolingo: 0,
            provasGreGmat: 0,
            selecaoUniversidades: 0,
            cartasDeRecomendacao: 0,
            cartasDeMotivacao: 0,
            documentos: 0
        }
    }


    // Proceed to the next page
    nextPage = () => {
        const { step } = this.state;
        this.setState({
            step: step + 1
        })
        this.submitForm()
    }

    submitForm = () => {

        axios.post(`${url}/api/forms/submit-form`, {
            form: {
                title: this.state.title,
                email: this.state.email,
                firstName: this.state.name,
                lastName: this.state.lastName,
                answers: this.state.answers
            },
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(resp => {
            this.setState({
                step: this.state.step + 1
            })

        }).catch(err => {
            console.log(err)
        });
    }

    // Go back to previous page
    prevPage = () => {
        const { step } = this.state;
        this.setState({
            step: step - 1
        })
    }

    // Handle fields change
    handleChange = input => e => {
        if (this.state.answers.hasOwnProperty(input)) {
            this.setState({ answers: { ...this.state.answers, [input]: parseInt(e.target.value) } })
        } else {
            this.setState({ [input]: e.target.value.trim() });
        }
    }

    render() {

        const { step } = this.state;
        const formValues = this.state;

        if (step === 0) {
            return (
                <div>
                    <Form nextStep={this.nextPage} step={step} values={formValues} handleChange={this.handleChange} />
                </div>
            );
        } else {
            return (
                <div>
                    <Relatorio prevStep={this.prevPage} values={formValues} />
                </div>
            );
        }


    }
}

export default MyComponent;
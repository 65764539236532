import '../../App.css';
import background from "../../img/background_pre_fundamentos.png";
import Main from "./Main";


function Home() {
  return (
      <div className="App"
           style={{
               backgroundImage: `url(${background})`,
               backgroundAttachment: 'fixed',
               backgroundSize: 'cover',
           }}>
          <Main style={{
              margin: "0",
              position: "absolute",
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)'
          }}/>
      </div>
  );
}

export default Home;

import {React, useState, useEffect} from 'react';
import {
    Button, Checkbox,
    FormControlLabel,
    FormLabel,
    Grid,
    Paper, Radio, RadioGroup,
    TextField, withStyles,
} from "@material-ui/core";
import * as PropTypes from "prop-types";
import logo from '../../img/Logo_Mentoria_BRASA_PRÉ_pós-graduação_colorida.png';
import {purple} from "@material-ui/core/colors";
import {Alert} from "@material-ui/lab";

function Field(props) {
    return null;
}

Field.propTypes = {
    label: PropTypes.string,
    fullWidth: PropTypes.bool,
    required: PropTypes.bool,
    name: PropTypes.string,
    component: PropTypes.func,
    type: PropTypes.string
};

const ColorButton = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(purple[500]),
        backgroundColor: '#175386',
        '&:hover': {
            backgroundColor: '#175386',
        },
    },
}))(Button);

function Rating(props) {
    return null;
}

Rating.propTypes = {
    size: PropTypes.string,
    name: PropTypes.string,
    defaultValue: PropTypes.number
};

function Form(props) {

    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const [emailError, setEmailError] = useState("");
    const [error, setError] = useState(false);

    const validateEmail = () => {
        if (props.values.email === "") return;

        if (!emailRegex.test(props.values.email)) {
            setEmailError("Insira um e-mail válido")
        } else {
            setEmailError("")
        }
    }

    // checa se alguma resposta ta vazia
    const checkEmptyFields = () => {
        for(let o in props.values){
            if (props.values[o] === ''){
                return true;
            }
        }
        return false;
    }

    const submit = e => {
        if (emailError === "" && !checkEmptyFields()) {
            e.preventDefault();
            props.nextStep();
        } else {
            setError(true)
        }
    }

    useEffect(() => {
        validateEmail();
    }, [props.values.email]);


    return (
        <div>
            <form>
                <Paper style={{padding: 50, opacity: "0.90", margin: "0 auto"}} className={"card-forms"} elevation={10}>
                    <Grid container spacing={6}>
                        <Grid container alignItems="center" spacing={10} style={{textAlign: 'center'}}>
                            <Grid item xs={12}>
                                <img src={logo} alt='Logo'/>
                            </Grid>
                        </Grid>
                        <Grid container spacing={6} style={{textAlign: 'center', paddingBottom: 20}}>
                            <Grid item  xs={12}>
                                <TextField
                                    className={"form-inputs"}
                                    label={"Nome"}
                                    onBlur={props.handleChange('name')}
                                    fullWidth/>
                            </Grid>
                            <Grid item  xs={12}>
                                <TextField
                                    className={"form-inputs"}
                                    label={"Sobrenome"}
                                    required={true}
                                    onBlur={props.handleChange('lastName')}
                                    fullWidth/>
                            </Grid>
                            <Grid item  xs={12}>
                                <TextField
                                    className={"form-inputs"}
                                    label={"Email"}
                                    required={true}
                                    onBlur={props.handleChange('email')}
                                    error={emailError !== ""}
                                    helperText={emailError}
                                    fullWidth/>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <FormLabel component="legend">Inglês - Reading</FormLabel>
                            <RadioGroup
                                onChange={props.handleChange('inglesReading')}
                                aria-required={true}
                                iaria-label="ingles-reading"
                                name="ingles-reading">
                                <FormControlLabel
                                    value="0"
                                    control={<Radio />}
                                    label="Básico / Iniciante" />
                                <FormControlLabel
                                    value="1"
                                    control={<Radio />}
                                    label="Intermediário" />
                                <FormControlLabel
                                    value="2"
                                    control={<Radio />}
                                    label="Avançado / Fluente" />
                            </RadioGroup>
                        </Grid>
                        <Grid item xs={12}>
                            <FormLabel component="legend">Inglês - Listening</FormLabel>
                            <RadioGroup
                                onChange={props.handleChange('inglesListening')}
                                aria-required={true}
                                iaria-label="ingles-listening"
                                name="ingles-listening">
                                <FormControlLabel
                                    value="0"
                                    control={<Radio />}
                                    label="Básico / Iniciante" />
                                <FormControlLabel
                                    value="1"
                                    control={<Radio />}
                                    label="Intermediário" />
                                <FormControlLabel
                                    value="2"
                                    control={<Radio />}
                                    label="Avançado / Fluente" />
                            </RadioGroup>
                        </Grid>
                        <Grid item xs={12}>
                            <FormLabel component="legend">Inglês - Writing</FormLabel>
                            <RadioGroup
                                onChange={props.handleChange('inglesWriting')}
                                aria-required={true}
                                iaria-label="ingles-writing"
                                name="ingles-writing">
                                <FormControlLabel
                                    value="0"
                                    control={<Radio />}
                                    label="Básico / Iniciante" />
                                <FormControlLabel
                                    value="1"
                                    control={<Radio />}
                                    label="Intermediário" />
                                <FormControlLabel
                                    value="2"
                                    control={<Radio />}
                                    label="Avançado / Fluente" />
                            </RadioGroup>
                        </Grid>
                        <Grid item xs={12}>
                            <FormLabel component="legend">Inglês - Speaking</FormLabel>
                            <RadioGroup
                                onChange={props.handleChange('inglesSpeaking')}
                                aria-required={true}
                                iaria-label="ingles-speaking"
                                name="ingles-speaking">
                                <FormControlLabel
                                    value="0"
                                    control={<Radio />}
                                    label="Básico / Iniciante" />
                                <FormControlLabel
                                    value="1"
                                    control={<Radio />}
                                    label="Intermediário" />
                                <FormControlLabel
                                    value="2"
                                    control={<Radio />}
                                    label="Avançado / Fluente" />
                            </RadioGroup>
                        </Grid>
                        <Grid item xs={12}>
                            <FormLabel component="legend">Provas - TOEFL / IELTS / Duolingo</FormLabel>
                            <RadioGroup
                                onChange={props.handleChange('provasToeflIeltsDuolingo')}
                                aria-required={true}
                                iaria-label="provas-toefl-ielts-duolingo"
                                name="provas-toefl-ielts-duolingo">
                                <FormControlLabel
                                    value="0"
                                    control={<Radio />}
                                    label="Não comecei a me preparar" />
                                <FormControlLabel
                                    value="1"
                                    control={<Radio />}
                                    label="Comecei a me preparar" />
                                <FormControlLabel
                                    value="2"
                                    control={<Radio />}
                                    label="Me sinto preparado(a), mas ainda não fiz a prova" />
                                <FormControlLabel
                                    value="3"
                                    control={<Radio />}
                                    label="Já fiz a prova e tenho o score que preciso" />
                                <FormControlLabel
                                    value="-1"
                                    control={<Radio />}
                                    label="Não se aplica" />
                            </RadioGroup>
                        </Grid>
                        <Grid item xs={12}>
                            <FormLabel component="legend">Provas - GRE / GMAT</FormLabel>
                            <RadioGroup
                                onChange={props.handleChange('provasGreGmat')}
                                aria-required={true}
                                iaria-label="provas-gre-gmat"
                                name="provas-gre-gmat">
                                <FormControlLabel
                                    value="0"
                                    control={<Radio />}
                                    label="Não comecei a me preparar" />
                                <FormControlLabel
                                    value="1"
                                    control={<Radio />}
                                    label="Comecei a me preparar" />
                                <FormControlLabel
                                    value="2"
                                    control={<Radio />}
                                    label="Me sinto preparado(a), mas ainda não fiz a prova" />
                                <FormControlLabel
                                    value="3"
                                    control={<Radio />}
                                    label="Já fiz a prova e tenho o score que preciso" />
                                <FormControlLabel
                                    value="-1"
                                    control={<Radio />}
                                    label="Não se aplica" />
                            </RadioGroup>
                        </Grid>
                        <Grid item xs={12}>
                            <FormLabel component="legend">Seleção de Universidades</FormLabel>
                            <RadioGroup
                                onChange={props.handleChange('selecaoUniversidades')}
                                aria-label="gender"
                                name="gender1">
                                <FormControlLabel
                                    value="0"
                                    control={<Radio />}
                                    label="Não sei como selecionar as universidades" />
                                <FormControlLabel
                                    value="1"
                                    control={<Radio />}
                                    label="Já comecei a analisar as universidades" />
                                <FormControlLabel
                                    value="2"
                                    control={<Radio />}
                                    label="Já decidi pra quais universidades aplicar" />
                                <FormControlLabel
                                    value="3"
                                    control={<Radio />}
                                    labelPlacement={"end"}
                                    label="Já listei todos os requerimentos de admissão de cada universidade" />
                            </RadioGroup>
                        </Grid>
                        <Grid item xs={12}>
                            <FormLabel component="legend">Cartas de Recomendação</FormLabel>
                            <RadioGroup
                                onChange={props.handleChange('cartasDeRecomendacao')}
                                aria-label="cartasDeRecomendacao"
                                name="cartasDeRecomendacao">
                                <FormControlLabel
                                    value="0"
                                    control={<Radio />}
                                    label="Não sei para quem pedir" />
                                <FormControlLabel
                                    value="1"
                                    control={<Radio />}
                                    label="Sei para quem pedir, mas ainda não pedi" />
                                <FormControlLabel
                                    value="2"
                                    control={<Radio />}
                                    label="Já concordaram em me recomendar" />
                                <FormControlLabel
                                    value="3"
                                    control={<Radio />}
                                    labelPlacement={"end"}
                                    label="As cartas já estão prontas para serem enviadas" />
                                <FormControlLabel
                                    value="-1"
                                    control={<Radio />}
                                    labelPlacement={"end"}
                                    label="Não se aplica" />
                            </RadioGroup>
                        </Grid>
                        <Grid item xs={12}>
                            <FormLabel component="legend">Cartas de Motivação</FormLabel>
                            <RadioGroup
                                onChange={props.handleChange('cartasDeMotivacao')}
                                aria-label="cartasDeMotivacao"
                                name="cartasDeMotivacao">
                                <FormControlLabel
                                    value="0"
                                    control={<Radio />}
                                    label="Não comecei a escrever" />
                                <FormControlLabel
                                    value="1"
                                    control={<Radio />}
                                    label="Já comecei a escrever" />
                                <FormControlLabel
                                    value="2"
                                    control={<Radio />}
                                    label="Já pedi para amigos/professores revisarem" />
                                <FormControlLabel
                                    value="3"
                                    control={<Radio />}
                                    labelPlacement={"end"}
                                    label="Carta(s) pronta(s)" />
                                <FormControlLabel
                                    value="-1"
                                    control={<Radio />}
                                    labelPlacement={"end"}
                                    label="Não se aplica" />
                            </RadioGroup>
                        </Grid>
                        <Grid item xs={12}>
                            <FormLabel component="legend">Documentos</FormLabel>
                            <RadioGroup
                                onChange={props.handleChange('documentos')}
                                aria-label="documentos"
                                name="documentos">
                                <FormControlLabel
                                    value="0"
                                    control={<Radio />}
                                    label="Não sei quais documentos vou precisar." />
                                <FormControlLabel
                                    value="1"
                                    control={<Radio />}
                                    label="Já sei quais documentos preciso, mas ainda falta mandar traduzir." />
                                <FormControlLabel
                                    value="2"
                                    control={<Radio />}
                                    label="Já tenho todas as traduções prontas." />
                                <FormControlLabel
                                    value="-1"
                                    control={<Radio />}
                                    labelPlacement={"end"}
                                    label="Não se aplica" />
                            </RadioGroup>
                        </Grid>
                        <Grid item xs={12} style={{textAlign: 'center'}}>
                            <FormControlLabel
                                control={<Checkbox
                                    defaultChecked={false}
                                    color="default"
                                    inputProps={{ 'aria-label': 'checkbox with default color' }}
                                />}
                                label="Li e concordo com os termos da BRASA"
                            />
                            <a target="_blank" href={'https://www.home.gobrasa.org/policies'}>https://www.home.gobrasa.org/policies</a>
                        </Grid>
                        {error ? <Grid item xs={12}>
                            <Alert className={'hide-print'} severity="error">
                                Um dos campos não está preenchido.
                            </Alert>
                        </Grid> : null}
                        <Grid item xs={12} style={{textAlign: 'center'}}>
                            <ColorButton
                                onClick={submit}
                                className="force-txt-white"
                                variant="contained">
                                Enviar!
                            </ColorButton>
                        </Grid>
                    </Grid>
                </Paper>
            </form>
        </div>
    );
}

export default Form;
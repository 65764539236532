import { React, useEffect, useState } from 'react';
import { Button, Fade, Grid, Paper, withStyles } from "@material-ui/core";
import 'react-svg-radar-chart/build/css/index.css'
import { Radar } from 'react-chartjs-2';
import axios from "axios";
import background from "../../img/background_pre_fundamentos.png";
import logo from "../../img/Logo_Mentoria_BRASA_PRÉ_pós-graduação_colorida.png";
import { Alert } from "@material-ui/lab";

const url = process.env.REACT_APP_BRASA_API_URL

function Relatorio(props) {
    const [radarData, setRadarData] = useState([
        {
            data: {
                type: 'radar',
                labels: [
                    'english-writing',
                    'english-speaking',
                    'english-listening',
                    'english-reading',
                    'toefl/ielts/duolingo',
                    'gre/gmat',
                    'recommendations',
                    'statement', 'documents'],
                datasets: [{
                    data: [0, 0, 0, 0, 0, 0, 0, 0, 0],
                }]
            },
            meta: { color: 'rgb(120, 178, 67)' }
        }
    ]);

    const ColorButton = withStyles((theme) => ({
        root: {
            backgroundColor: '#175386',
            '&:hover': {
                backgroundColor: '#175386',
            },
        },
    }))(Button);

    const [customText, setCustomText] = useState("");

    const calculateCatScore = (scoresArray, scoreMax) => {
        //let scorePerQuestion = scoreMax / scoresArray.length;
        let counter = 0;
        for (let i of scoresArray) {
            if (parseInt(i) === -1) {
                //scoreMax -= scorePerQuestion;
                return -1;
            } else {
                counter += parseInt(i);
            }
        }
        return counter / (scoreMax <= 0 ? 1 : scoreMax);
    }

    const calculateScores = (notas) => {
        // DO calculation here
        return {
            absolute: {
                sel_uni: notas.selecaoUniversidades,
                reading: notas.inglesReading,
                listening: notas.inglesListening,
                writing: notas.inglesWriting,
                speaking: notas.inglesSpeaking,
                toefl: notas.provasToeflIeltsDuolingo,
                gre_gmat: notas.provasGreGmat,
                rec_letter: notas.cartasDeRecomendacao,
                sop: notas.cartasDeMotivacao,
                docs: notas.documentos,
            },
            percentage: {
                englishReading: calculateCatScore([notas.inglesReading], 2),
                englishListening: calculateCatScore([notas.inglesListening], 2),
                englishWriting: calculateCatScore([notas.inglesWriting], 2),
                englishSpeaking: calculateCatScore([notas.inglesSpeaking], 2),
                toelfIeltsDuoloing: calculateCatScore([notas.provasToeflIeltsDuolingo], 3),
                greGmat: calculateCatScore([notas.provasGreGmat], 3),
                recommendations: calculateCatScore([notas.cartasDeRecomendacao], 3),
                statement: calculateCatScore([notas.cartasDeMotivacao], 3),
                documents: calculateCatScore([notas.documentos], 2)
            }
        }
    }

    const generateRadar = (score) => {
        setRadarData([
            {
                data: {
                    labels: [
                        'Reading',
                        'Listening',
                        'Writing',
                        'Speaking',
                        'Toefl/ielts/duolingo',
                        'Gre/gmat',
                        'Recommendations',
                        'Statement',
                        'Documents'].reduce(function (acc, currentValue) {
                            if (currentValue === 'Toefl/ielts/duolingo') {
                                if (score.toelfIeltsDuoloing !== -1) {
                                    return acc.concat(currentValue);
                                }
                            } else if (currentValue === 'Gre/gmat') {
                                if (score.greGmat !== -1) {
                                    return acc.concat(currentValue);
                                }
                            } else if (currentValue === 'Recommendations') {
                                if (score.recommendations !== -1) {
                                    return acc.concat(currentValue);
                                }
                            } else if (currentValue === 'Statement') {
                                if (score.statement !== -1) {
                                    return acc.concat(currentValue);
                                }
                            } else if (currentValue === 'Documents') {
                                if (score.documents !== -1) {
                                    return acc.concat(currentValue);
                                }
                            } else {
                                return acc.concat(currentValue)
                            }
                            return acc;
                        }, []),
                    datasets: [{
                        data: [
                            score.englishReading,
                            score.englishListening,
                            score.englishWriting,
                            score.englishSpeaking,
                            score.toelfIeltsDuoloing,
                            score.greGmat,
                            score.recommendations,
                            score.statement,
                            score.documents
                        ].reduce(function (acc, currentValue, currentIndex) {
                            if (currentValue !== -1) {
                                acc.push(currentValue);
                                return acc;
                            }
                            return acc;
                        }, []),
                        backgroundColor: 'rgb(120, 178, 67, 0.75)'
                    }
                    ],
                },
                meta: { color: 'rgb(120, 178, 67, 0.75)' }
            }
        ]);
    }

    async function getTextByName(name, value) {
        return await fetch("/texts/" + name + '/' + value + '.brasa')
            .then(response => response.text())
            .then(data => {
                return data;
            })
    }

    const createText = async form => {
        let aux = "";
        for (const [key, value] of Object.entries(form)) {
            if (value !== -1) {
                await getTextByName(key, value).then(response => response !== "" ? (aux += " <br><br> " + response) : "");
            }
        }
        return aux;
    }

    const setReport = (submission) => {
        let score = calculateScores(submission);
        generateRadar(score.percentage);
        createText(score.absolute).then(r => setCustomText(r));
    }

    useEffect(async () => {
        if (!props.match || !props.match.params.id) {
            await setReport(props.values.answers);
        } else if (props.match && props.match.params.id) {
            await axios.get(`${url}/api/forms/submission`, {
                params: {
                    form_id: props.match.params.id
                }
            }).then(resp => {
                if (resp.data.status === true) {
                    setReport(resp.data.submission.answers);
                } else {
                    window.location.href = "/";
                }
            }).catch(err => {
                console.log(err)
            });
        } else {
            window.location.href = "/";
        }
    }, []);

    return (
        <div className="App"
            style={{
                backgroundImage: `url(${background})`,
                backgroundAttachment: 'fixed',
                backgroundSize: 'cover',
            }}>
            <div className="section-to-print">
                <Paper style={{
                    minHeight: '100vh',
                    padding: 50,
                    opacity: "0.90",
                    margin: "0 auto"
                }} className={"card-forms"} elevation={10} square={true}>
                    <Grid container spacing={6}>
                        <Grid container style={{ textAlign: 'center' }} alignItems="center" spacing={3}>
                            <Grid item xs={12}>
                                <img className={'hide-print'} src={logo} alt='Logo' />
                            </Grid>
                            <Grid item xs={12}>
                                <h2 style={{ margin: 'auto', fontWeight: 'bold' }}>Relatório</h2>
                            </Grid>
                            <Grid item xs={12} className={"chart-wrapper"} style={{
                                height: '15%',
                                width: '100%',
                                textAlign: 'center',
                                padding: '0.5%'
                            }}>
                                <Fade in={true}>
                                    <Radar
                                        data={radarData[0].data}
                                        type={'radar'}
                                        height={null}
                                        width={null}
                                        options={{
                                            scale: {
                                                min: 0,
                                                max: 1,
                                            },
                                            responsive: true,
                                            maintainAspectRatio: false,
                                            plugins: {
                                                legend: {
                                                    display: false
                                                }
                                            }
                                        }}
                                    />
                                </Fade>
                            </Grid>
                            <Grid item xs={12}>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: customText
                                    }} />
                            </Grid>
                            <Grid item xs={12}>
                                <ColorButton onClick={() => window.print()} className="force-txt-white hide-print" variant="contained">Download Report</ColorButton>
                            </Grid>
                            <Grid item xs={12}>
                                <Alert className={'hide-print'} severity="info">
                                    Também enviamos o relatório pelo seu email - cheque a caixa de SPAM.
                                </Alert>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </div>
        </div>
    );
}

export default Relatorio;
import React, { useState, useEffect, useRef } from "react";
import Nav from '../Nav';
import '../../styles/Admin.css';
import axios from "axios";
import ExcelFile from "react-export-excel/dist/ExcelPlugin/components/ExcelFile";
import ExcelColumn from "react-export-excel/dist/ExcelPlugin/elements/ExcelColumn";
import ExcelSheet from "react-export-excel/dist/ExcelPlugin/elements/ExcelSheet";
import { usePromiseTracker } from "react-promise-tracker";
import { trackPromise } from 'react-promise-tracker';
import Loader from 'react-loader-spinner';
import Pagination from '@material-ui/lab/Pagination';

const url = process.env.REACT_APP_BRASA_API_URL

function Dashboard() {
    const [chevronAtivo, setChevronAtivo] = useState(false);
    const [submissions, setSubmissions] = useState([]);

    const [dataExcel, setDataExcel] = useState({ labels: [], values: [] });

    const [toBeDeleted, setToBeDeleted] = useState("");
    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    const [inputForm, setInputForm] = useState("todos");
    const [inputNome, setInputNome] = useState("");
    const [inputEmail, setInputEmail] = useState("");
    const [inputDesde, setInputDesde] = useState("");
    const [inputAte, setInputAte] = useState("");
    const [checkboxDeletados, setCheckboxDeletados] = useState(false);

    const PAGE_SIZE = 20;
    const [currentPage, setCurrentPage] = useState(1);
    const [submissionCount, setSubmissionCount] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const iref = useRef(null);


    useEffect(() => {
        bringThemAll();
    }, [inputForm, inputNome, inputEmail, inputDesde, inputAte, checkboxDeletados, currentPage]);

    useEffect(() => {
        if (dataExcel.values && dataExcel.values.length > 0 && iref.current !== null) {
            iref.current.click();
        }
    }, [dataExcel]);

    const handleString = (string, callback) => {
        if (string.length > 2 || string.length === 0) {
            callback(string);
        }
    }

    const getOffset = () => {
        return (currentPage - 1) * PAGE_SIZE;
    }

    const LoadingIndicator = props => {
        const { promiseInProgress } = usePromiseTracker();

        return (
            promiseInProgress &&
            <div style={{
                width: "100%",
                height: "100",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}
            >
                <Loader type="ThreeDots" color="#383838" height="100" width="100" />
            </div>
        );
    }

    const bringThemAll = () => {

        trackPromise(
            axios.get(`${url}/api/forms/filtered-submissions`, {
                params: {
                    offset: getOffset(),
                    page_size: PAGE_SIZE,
                    title: inputForm,
                    name: inputNome,
                    email: inputEmail,
                    desde: inputDesde,
                    ate: inputAte,
                    hidden: checkboxDeletados
                }
            }).then(res => {
                if (res.data.status === true) {
                    setSubmissionCount(Number(res.data.total_count));
                    setPageCount(Number(res.data.page_count));
                    if (pageCount < currentPage) {
                        setCurrentPage(1);
                    }
                    setSubmissions(res.data.form_submissions);
                }
            }).catch(err => {
                console.log(err);
            }));
    }

    const retrieveExcelEntries = () => {

        trackPromise(
            axios.get(`${url}/api/forms/filtered-submissions`, {
                params: {
                    page_size: submissionCount,
                    offset: 0,
                    title: inputForm,
                    name: inputNome,
                    email: inputEmail,
                    desde: inputDesde,
                    ate: inputAte,
                    hidden: checkboxDeletados
                }
            }).then(res => {
                if (res.data.status === true && res.data.form_submissions.length > 0) {
                    setDataExcel({
                        labels: ['title', 'firstName', 'lastName', 'email', 'submissionTime']
                            .concat(Object.keys(res.data.form_submissions[0].answers).map((key) => key)).concat(['hidden']),
                        values: res.data.form_submissions.reduce(((acc, el) => {
                            acc.push({ ...el, ...el.answers })
                            return acc;
                        }), [])
                    })
                }
            })
        )
    }

    function deletarSubmission() {
        setOpenDeleteModal(false);

        axios.delete(`${url}/api/forms/submission`, {
            data: {
                form_id: toBeDeleted
            }
        })
            .then(res => {
                bringThemAll();
            })
            .catch(err => {
                console.log(err);
            });
    }

    function abrirModalDeletarFormSubm(form_id) {
        setToBeDeleted(form_id);
        setOpenDeleteModal(true);
    }

    const user = JSON.parse(localStorage.getItem(process.env.REACT_APP_MY_USER));

    function logout() {
        localStorage.removeItem(process.env.REACT_APP_MY_USER);
        window.location = "/admin";
    }

    return (
        <main>
            <Nav />
            <div className="container main">
                <div className="row">
                    <div className="col-12">
                        <h1>Olá {user.Apelido !== "" ? user.Apelido : user.Time},</h1>
                    </div>
                    <div className="col-12 col-md-10 mb-3">
                        <div className="card">
                            <div className="card-body bg-dark-yellow">
                                <div className="row">
                                    <div className="col-12 col-md-3 text-center">
                                        <div className="number-circle">{submissionCount}</div>
                                    </div>
                                    <div className="col-12 col-md-9 ">
                                        <p className="desc-stats">Total de Preenchimentos</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"col-12 col-md-2 my-5" + (submissions.length > 0 ? "" : " d-none")}>
                        <i onClick={retrieveExcelEntries} className="text-green far fa-file-excel middle-transform export-excel" />
                        <ExcelFile filename="Diagnóstico" element={<div ref={iref}> </div>}>
                            <ExcelSheet data={dataExcel.values} name="Submissões">
                                {
                                    dataExcel.labels.map(
                                        (label) =>
                                            <ExcelColumn key={label} label={label} value={label} />
                                    )
                                }
                            </ExcelSheet>
                        </ExcelFile>
                    </div>
                </div>
                <button className="btn time-collpase-title bg-dark-grey border-0" aria-expanded="false" onClick={() => setChevronAtivo(!chevronAtivo)}>
                    <span className="text-white">Filtros</span>
                    <i className={"fas " + (chevronAtivo ? "fa-chevron-up" : "fa-chevron-down")} /></button>
                <div className={"collapse " + (chevronAtivo ? 'show' : '')}>
                    <div className="card card-body mb-4">
                        <form>
                            <div className="form-group">
                                <label htmlFor="formData">Form</label>
                                <select className="form-control" id="formData" onChange={(e) => setInputForm(e.target.value)}>
                                    <option value="todos">Todos</option>
                                    <option>Fundamentos Grad</option>
                                </select>

                            </div>
                            <div className="form-group">
                                <label htmlFor="nomeOuSobrenome">Nome ou Sobrenome</label>
                                <input aria-describedby="nomeHelp" type="text" className="form-control" id="nomeOuSobrenome" placeholder="Nome Completo ou parte dele" onChange={(e) => handleString(e.target.value, setInputNome)} />
                                <small id="nomeHelp" className="form-text text-muted">Pelo menos 3 caracteres.</small>

                            </div>
                            <div className="form-group">
                                <label htmlFor="email">Email</label>
                                <input aria-describedby="emailHelp" type="text" className="form-control" id="email" placeholder="Email ou parte dele" onChange={(e) => handleString(e.target.value, setInputEmail)} />
                                <small id="emailHelp" className="form-text text-muted">Pelo menos 3 caracteres.</small>

                            </div>
                            <div className="form-row">
                                <div className="col">
                                    <label htmlFor="dataInicio">Preenchido depois de:</label>
                                    <input type="date" className="form-control" id="dataInicio" onChange={(e) => setInputDesde(e.target.value)} />
                                </div>
                                <div className="col">
                                    <label htmlFor="dataFim">Preenchido até:</label>
                                    <input type="date" className="form-control" id="dataFim" placeholder="Nome Completo ou parte dele" onChange={(e) => setInputAte(e.target.value)} />
                                </div>
                            </div>
                            <div className="form-check form-switch mt-3">
                                <input className="form-check-input" type="checkbox" id="exibirDeletados" onChange={(e) => setCheckboxDeletados(e.target.checked)} />
                                <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Exibir também os deletados</label>
                            </div>
                        </form>
                    </div>
                </div>
                <hr />
                <LoadingIndicator />
                <div className="card card-body mb-5">
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Nome</th>
                                <th scope="col">E-mail</th>
                                <th scope="col">Data</th>
                                <th scope="col" className="thin-column" colSpan="2"><i className="fas fa-cog" /></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                submissions.map(
                                    (submission, index) =>
                                        <tr key={submission._id}>

                                            <th scope="row">{index + 1}</th>
                                            <td>{submission.firstName} {submission.lastName}</td>
                                            <td>{submission.email}</td>
                                            <td>{new Date(submission.submissionTime).toISOString().substr(0, 10)}</td>
                                            <td>
                                                <a target="_blank" href={"/relatorio/" + submission._id} className="btn bg-primary" rel="noreferrer"><i className="fas fa-eye text-white" /></a>
                                            </td>
                                            <td>
                                                <span onClick={() => abrirModalDeletarFormSubm(submission._id)} className="btn bg-danger"><i className="fas fa-trash-alt text-white" /></span>
                                            </td>
                                        </tr>
                                )
                            }

                        </tbody>
                    </table>
                    <div className={"text-center justify-content-center mt-5 " + (pageCount <= 1 ? "d-none" : "d-flex")}>
                        <Pagination count={pageCount} page={currentPage} onChange={(event, value) => setCurrentPage(value)} />
                    </div>
                </div>
            </div>

            <div id="delete-modal" style={openDeleteModal ? { display: "block" } : { display: "none" }} className="modal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalCenterTitle">Deletar Submissão</h5>
                            <button onClick={() => setOpenDeleteModal(false)} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <i className="fas fa-times" />
                            </button>
                        </div>
                        <div className="modal-body">
                            Confirma que deseja deletar esta submissão?<br /> Isso só poderá ser desfeito com ajuda de alguém de tech.
                        </div>
                        <div className="modal-footer">
                            <button onClick={() => setOpenDeleteModal(false)} type="button" className="btn btn-secondary">Cancelar</button>
                            <button onClick={() => deletarSubmission()} type="button" className="btn btn-danger">Deletar</button>
                        </div>
                    </div>
                </div>
            </div>
            <footer className="bg-grey text-center text-lg-start">
                <div className="text-center p-3 text-white">
                    BRASA © {new Date().getFullYear()} Copyright
                </div>
            </footer>
        </main>
    );
}

export default Dashboard;